<template>
  <div class="header">
    <div class="topStyle" style="display: inline-block">
      <a-card :bordered="false">
        <div style="text-align: right">
          <a-form layout="inline" style="text-align: right;">
            <a-form-item label="所属城市">
              <a-select
                @change="selectCity()"
                class="header-select"
                style="width: 100px;"
                v-model="queryParam.cityName"
                placeholder="请选择"
              >
                <a-select-option v-for="item in userCitiesAll" :key="item.id">{{
                  item.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
<!--            <a-form-item label="厨房品牌" v-show="showBrandSelector()">
              <a-select
                class="header-select"
                style="width: 100px;"
                @change="selectBrand()"
                v-model="queryParam.brandName"
                placeholder="请输入"
              >
                <a-select-option
                  v-for="item in brandNameList"
                  :key="item.code"
                  >{{ item.optionText }}</a-select-option
                >
              </a-select>
            </a-form-item>-->
          </a-form>
        </div>
      </a-card>
    </div>
    <a-dropdown>
      <span class="action ant-dropdown-link user-dropdown-menu">
        <span>{{ this.name || "null" }}</span>
      </span>
      <a-menu slot="overlay" class="user-dropdown-menu-wrapper">
        <a-menu-item key="1">
          <a @click="changePassword">
            <span>修改密码</span>
          </a>
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item key="3">
          <a @click="goLogout">
            <span>退出登录</span>
          </a>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <a-modal
      title="修改密码"
      :width="640"
      :visible="visiblaReset"
      destroyOnClose
      :confirmLoading="confirmLoading"
      okText="提交"
      @ok="handleSubmit"
      @cancel="handleCancel"
    >
      <a-form :form="form">
        <a-form-item
          label="输入原密码"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            v-decorator="[
              'oldPwd',
              {
                rules: [{ required: true, message: '请输入旧密码' }]
              }
            ]"
            type="password"
            placeholder="请输入旧密码"
          />
        </a-form-item>
        <a-form-item
          label="输入新密码"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            @change="changenewPwdList"
            v-decorator="[
              'newPwd',
              {
                rules: [
                  {
                    required: true,
                    pattern: /^[0-9a-zA-Z_]{6,20}$/,
                    message: '（登录密码必须是6-20位字母、数字及其综合组合）'
                  }
                ]
              }
            ]"
            type="password"
            placeholder="请输入新密码"
          />
        </a-form-item>
        <a-form-item
          label="再次确认新密码"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            @change="changenewPwdtwoList"
            v-decorator="[
              'newPwdtwo',
              {
                rules: [
                  {
                    required: true,
                    pattern: /^[0-9a-zA-Z_]{6,20}$/,
                    message: '密码不足6位'
                  }
                ]
              }
            ]"
            type="password"
            placeholder="请再次确认新密码"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { GET_USER_CITIES } from "../store/modules/actions.type";
import axios from "axios";
  
import Bus from "../bus";
import { getAuthenticatedUserId } from "../utils/user";

export default {
  components: {},
  data() {
    return {
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 }
      },
      //厨房品牌
      brandNameList: [{ code: 0, optionText: "全部" }],
      queryParam: {
        brandName: 0,
        cityName: 0
      },
      name: "null",
      visiblaReset: false,
      confirmLoading: false,
      newPwdList: [],
      newPwdtwoList: []
    };
  },
  watch: {
    "queryParam.cityName"(a, b) {
      Bus.$emit("getTarget", event.target);
    },
    "queryParam.brandName"(a, b) {
      Bus.$emit("getTarget", event.target);
    }
  },
  created() {
    sessionStorage.setItem("cid", 0);
    sessionStorage.setItem("bid", 0);
    this.name = localStorage.getItem("name");
    this.init();
  },
  computed: {
    ...mapGetters("cities", ["userCitiesAll"])
  },

  methods: {
    ...mapActions("login", ["logout"]),
    ...mapActions("cities", [GET_USER_CITIES]),
    // 获取城市
    init() {
      this.getUserCities();
      this.getKitchenList();
    },
    // 根据城市获取厨房品牌
    getKitchenList(val) {
      axios({
        url:   this.$baseUrl + "facilityBrand/select",
        method: "GET",
        params: {
          cityId: this.queryParam.cityName | val
        }
      }).then(res => {
        if (res.data.rows) {
          if (res.data.rows) {
            res.data.rows.unshift({ code: 0, optionText: "全部" });
            this.brandNameList = res.data.rows;
            this.brandNameList.forEach(item => {
              item.code = Number(item.code);
            });
          }
        }
      });
    },
    changenewPwdList(val) {
      this.newPwdList.push(val.data);
    },
    changenewPwdtwoList(val) {
      this.newPwdtwoList.push(val.data);
    },
    handleSubmit() {
      const {
        form: { validateFields }
      } = this;
      validateFields((errors, values) => {
        if (!errors) {
          if (values.newPwd === values.newPwdtwo) {
            axios({
              url: `${  this.$baseUrl}/users/${getAuthenticatedUserId()}/password`,
              method: "POST",
              data: {
                ...values
              }
            }).then(res => {
              if (res.data.returnCode === 200) {
                this.$message.success("恭喜你，密码修改成功，请重新登陆！");
                this.$router.push({
                  path: "/user/login"
                });
              } else {
                this.$message.error(`${res.data.returnMsg}`);
              }
              this.visiblaReset = false;
            });
          } else {
            this.$message.error("两次密码输入不一致，请重新输入！");
          }
        }
      });
    },
    handleCancel() {
      this.visiblaReset = false;
    },
    changePassword() {
      this.visiblaReset = true;
    },
    goLogout() {
      this.logout();
    },
    selectCity() {
      sessionStorage.setItem("cid", this.queryParam.cityName);
      Bus.$emit("getTarget", {});
      this.resetSetItem("watchStoragecid", this.queryParam.cityName);
      this.brandNameList = [];
      this.getKitchenList();
      this.queryParam.brandName = 0;
    },
    selectBrand() {
      sessionStorage.setItem("bid", this.queryParam.brandName);
      Bus.$emit("getTarget", {});
      this.resetSetItem("watchStoragebid", this.queryParam.brandName);
    },
    showBrandSelector() {
      let hideBrandSelector = false;
      const route = this.$route;
      if (route && route.meta) {
        hideBrandSelector = route.meta.hideBrandSelector === true;
      }

      return hideBrandSelector === false;
    }
  }
};
</script>

<style scoped>
.header {
  float: right;
  margin-right: 30px;
}
</style>
