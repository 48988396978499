<template>
  <div style="width: 180px">
    <a-menu
      style="background: #47494A"
      mode="inline"
      :theme="theme"
      :openKeys.sync="openKeys"
      :selectedKeys="selectedKeys"
    >
      <template>
        <a-sub-menu v-for="item in rootSubmenuKeys" v-bind:key="item.path">
          <span slot="title" class="titleHover">
            <a-avatar
              shape="square"
              class="menuListImg"
              style="background-color:#47494a"
              :src="item.meta.icon"
            />&nbsp;
            <span :class="collapsed === true ? 'displayNone' : ''">
              {{ item.meta && item.meta.title }}
            </span>
          </span>
          <a-menu style="background: #292929; width: 181px">
            <a-menu-item
              style="margin: 0"
              v-for="sub in item.children"
              :key="sub.path"
              @click="
                () => 
                  $router.push({ path: sub.path })
                
              "
            >
              {{ sub.meta.title }}
            </a-menu-item>
          </a-menu>
        </a-sub-menu>
      </template>
    </a-menu>
  </div>
</template>

<script>
import { check } from "../utils/auth";

export default {
  props: {
    theme: {
      type: String,
      default: "dark"
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    authenticatedUser: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  watch: {
    "$route.path": function() {
      this.openKeys = this.calculateOpenKeys(this.routes);
      this.selectedKeys = this.calculateSelectedKeys(this.routes);
    },
    collapsed() {
      this.openKeys = this.calculateOpenKeys(this.routes);
      this.selectedKeys = this.calculateSelectedKeys(this.routes);
    }
  },
  data() {
    const routes = this.$router.options.routes[0].children;
    return {
      routes,
      openKeys: this.calculateOpenKeys(routes),
      selectedKeys: this.calculateSelectedKeys(routes)
    };
  },
  computed: {
    rootSubmenuKeys: function() {
      return this.getMenuButtons(this.$router.options.routes[0].children);
    }
  },
  methods: {
    calculateOpenKeys(routes) {
      if (this.collapsed) {
        return [];
      }
      return routes
        .filter(route => this.$route.path.startsWith(route.path))
        .map(route => route.path);
    },
    calculateSelectedKeys(routes) {
      return routes
        .flatMap(route => route.children)
        .filter(route => route.path === this.$route.path)
        .map(route => route.path);
    },
    getMenuButtons(routes) {
      const menuButtons = [];
      // Only add routes which both show in menu and for which at least one child route is visible and accessible
      routes
        .filter(route => route.meta && route.meta.showInMenu)
        .forEach(route => {
          const children = [];
          route.children.forEach(childRoute => {
            if (
              childRoute.meta &&
              childRoute.meta.showInMenu &&
              check(childRoute, this.authenticatedUser.actions)
            ) {
              children.push(childRoute);
            }
          });
          if (children.length === 0) {
            return;
          }
          menuButtons.push({
            ...route,
            children
          });
        });
      return menuButtons;
    }
  }
};
</script>
