var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[`nav-theme-${_vm.navTheme}`, `nav-layout-${_vm.navLayout}`]},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-layout',{staticStyle:{"min-height":"100vh"}},[(_vm.navLayout === 'left')?_c('a-layout-sider',{staticStyle:{"background":"#47494A"},attrs:{"theme":_vm.navTheme,"trigger":null,"collapsible":"","width":"180px"},model:{value:(_vm.collapsed),callback:function ($$v) {_vm.collapsed=$$v},expression:"collapsed"}},[_c('div',{staticClass:"logo"},[_c('a-avatar',{staticClass:"logoAvatar",attrs:{"shape":"square","src":_vm.title}}),(!_vm.collapsed)?_c('span',{staticClass:"logoTitle"},[_vm._v(" 门店管理系统 ")]):_vm._e()],1),_c('SiderMenu',{attrs:{"theme":_vm.navTheme,"collapsed":_vm.collapsed,"authenticatedUser":_vm.authenticatedUser}})],1):_vm._e(),(_vm.authenticated)?_c('a-layout',[_c('a-layout-header',{staticStyle:{"background":"#fff","padding":"0"}},[_c('a-icon',{staticClass:"trigger",attrs:{"type":_vm.collapsed ? 'menu-unfold' : 'menu-fold'},on:{"click":function($event){_vm.collapsed = !_vm.collapsed}}}),_c('Header')],1),_vm._l((_vm.routes),function(route,index){return _c('div',{key:index},[(_vm.shouldShowBreadcrumb(_vm.$route.path, route.path))?_c('div',{staticClass:"breadcrumb"},_vm._l((route.children),function(path){return _c('div',{key:path.key,staticClass:"breadcrumbchild"},[(
                  path.path === _vm.$route.path && path.meta.showInMenu === true
                )?_c('span',[_c('router-link',{attrs:{"to":{ path: route.path }}},[_vm._v(" "+_vm._s(route.meta.title)+" ")]),_vm._v("/ "),_c('span',{staticClass:"click",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.goClick(path.path)}}},[_vm._v(" "+_vm._s(path.meta.title)+" ")]),_c('br'),_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(path.meta.title))])],1):_vm._e(),(
                  path.path !== _vm.$route.path &&
                    _vm.$route.path.includes(path.path) &&
                    path.meta &&
                    path.meta.showInMenu === true
                )?_c('span',[_c('router-link',{attrs:{"to":{ path: route.path === '' ? '/' : route.path }}},[_vm._v(" "+_vm._s(route.meta.title)+" ")]),_vm._v("/ "),_c('span',{staticClass:"click",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.goClick(path.path)}}},[_vm._v(_vm._s(path.meta.title)+" ")]),(_vm.$route.path.includes('Detail'))?_c('span',[_vm._v("/"+_vm._s("详情"))]):_c('span',[_vm._v("/"+_vm._s("编辑")+" ")]),_c('br'),(_vm.$route.path.includes('Detail'))?_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(path.meta.title + "详情"))]):_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(path.meta.title + "编辑"))])],1):_vm._e()])}),0):_vm._e()])}),_c('a-layout-content',{staticStyle:{"margin":"24px 24px 0"}},[_c('keep-alive',[(_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e()],1),(!_vm.$route.meta.keepAlive)?_c('router-view'):_vm._e()],1),_c('a-layout-footer',{staticStyle:{"text-align":"center"}})],2):_vm._e(),(!_vm.authenticated && !_vm.loading)?_c('div',{staticStyle:{"width":"100%"}},[_c('Unauthorized')],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }