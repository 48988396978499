<template>
  <div :class="[`nav-theme-${navTheme}`, `nav-layout-${navLayout}`]">
    <a-spin :spinning="loading">
      <a-layout style="min-height: 100vh">
        <a-layout-sider
          style="background: #47494A"
          v-if="navLayout === 'left'"
          :theme="navTheme"
          :trigger="null"
          collapsible
          v-model="collapsed"
          width="180px"
        >
          <div class="logo">
            <a-avatar shape="square" :src="title" class="logoAvatar" />
            <span v-if="!collapsed" class="logoTitle"> 门店管理系统 </span>
          </div>
          <SiderMenu
            :theme="navTheme"
            :collapsed="collapsed"
            :authenticatedUser="authenticatedUser"
          />
        </a-layout-sider>
        <a-layout v-if="authenticated">
          <a-layout-header style="background: #fff; padding: 0">
            <a-icon
              class="trigger"
              :type="collapsed ? 'menu-unfold' : 'menu-fold'"
              @click="collapsed = !collapsed"
            ></a-icon>
            <Header />
          </a-layout-header>
          <div v-for="(route, index) in routes" v-bind:key="index">
            <div
              v-if="shouldShowBreadcrumb($route.path, route.path)"
              class="breadcrumb"
            >
              <div
                class="breadcrumbchild"
                v-for="path in route.children"
                v-bind:key="path.key"
              >
                <span
                  v-if="
                    path.path === $route.path && path.meta.showInMenu === true
                  "
                >
                  <router-link :to="{ path: route.path }">
                    {{ route.meta.title }} </router-link
                  >/
                  <span class="click" @click.stop.prevent="goClick(path.path)">
                    {{ path.meta.title }}
                  </span>

                  <br />
                  <span style="font-size: 20px">{{ path.meta.title }}</span>
                </span>
                <span
                  v-if="
                    path.path !== $route.path &&
                      $route.path.includes(path.path) &&
                      path.meta &&
                      path.meta.showInMenu === true
                  "
                >
                  <router-link
                    :to="{ path: route.path === '' ? '/' : route.path }"
                  >
                    {{ route.meta.title }} </router-link
                  >/
                  <span class="click" @click.stop.prevent="goClick(path.path)"
                    >{{ path.meta.title }}
                  </span>
                  <span v-if="$route.path.includes('Detail')"
                    >/{{ "详情" }}</span
                  >
                  <span v-else>/{{ "编辑" }} </span>
                  <br />
                  <span
                    v-if="$route.path.includes('Detail')"
                    style="font-size: 20px"
                    >{{ path.meta.title + "详情" }}</span
                  >
                  <span v-else style="font-size: 20px">{{
                    path.meta.title + "编辑"
                  }}</span>
                </span>
              </div>
            </div>
          </div>
          <a-layout-content style="margin: 24px 24px 0;">
            <keep-alive>
              <router-view v-if="$route.meta.keepAlive"></router-view>
            </keep-alive>
            <router-view v-if="!$route.meta.keepAlive"></router-view>
          </a-layout-content>
          <a-layout-footer style="text-align: center"> </a-layout-footer>
        </a-layout>
        <div v-if="!authenticated && !loading" style="width: 100%">
          <Unauthorized />
        </div>
      </a-layout>
    </a-spin>
  </div>
</template>

<script>
import Header from "./Header";
import SiderMenu from "./SiderMenu";
import Unauthorized from "../views/Exception/Unauthorized";
import title from "../assets/logo.png";
import { mapActions, mapGetters } from "vuex";
import { GET_AUTHENTICATED_USER_DETAILS } from "../store/modules/actions.type";
import { check } from "../utils/auth";

export default {
  data() {
    return {
      title,
      collapsed: false,
      loading: false,
      routes: this.$router.options.routes[0].children
    };
  },
  created: async function() {
    if (!this.authenticatedUser || !this.authenticatedUser.actions.length) {
      this.loading = true;
      await this.getAuthenticatedUserDetails();
      this.loading = false;
    }
  },
  methods: {
    ...mapActions("users", [GET_AUTHENTICATED_USER_DETAILS]),
    goClick(path) {
      this.$router.push({
        path: path
      });
    },
    shouldShowBreadcrumb(path, url) {
      let bPath = path.split("/")[1];
      let bUrl = url.split("/")[1];
      return bPath === bUrl;
    }
  },
  computed: {
    ...mapGetters("users", ["authenticatedUser"]),
    navTheme() {
      return this.$route.query.navTheme || "dark";
    },
    navLayout() {
      return this.$route.query.navLayout || "left";
    },
    authenticated() {
      return check(this.$route, this.authenticatedUser.actions);
    }
  },
  components: {
    Header,
    SiderMenu,
    Unauthorized
  }
};
</script>

<style scoped lang="less">
@import "src/styles/variables.less";

.trigger {
  padding: 0 20px;
  line-height: 64px;
  font-size: 20px;

  &:hover {
    background: #eeeeee;
  }
}
.breadcrumb {
  background: #fff;
  padding: 0 32px;
  height: 98px;
  box-shadow: inset 0 3px 2px #f0f2f5;
  .breadcrumbchild {
    margin-top: 6px;
    line-height: 38px;
    float: left;
  }
}

.logo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 0 0.8em 0;
  overflow: hidden;
  background: #47494a;
  .logoTitle {
    font-size: 1em;
    height: 3em;
    font-family: PingFang-SC-Bold, PingFang-SC, sans-serif;
    font-weight: bold;
    color: #ffffff;
    line-height: 3em;
    padding-left: 3px;
    display: inline-block;
  }
  .logoAvatar {
    width: 3em;
    height: 3em;
    display: inline-block;
    background: #47494a;
  }

  svg {
    width: 32px;
    height: 32px;
    display: inline-block;
    vertical-align: middle;
  }

  h1 {
    display: inline-block;
    margin: 0 0 0 12px;
    font-size: 20px;
    font-family: Avenir, "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-weight: 600;
    vertical-align: middle;
  }
}

.nav-theme-dark {
  /deep/ .logo {
    h1 {
      color: #ffffff;
    }
  }
}
.click:hover {
  color: @primary-color;
  cursor: pointer;
}
</style>
